import React from 'react';

import HelmetLayout from '../components/layouts/helmet_layout/helmet_layout';
import MainLayout from '../components/layouts/main_layout/main_layout';
import BlogHeader from '../components/blog/blog_header/blog_header';
import BlogPostsSection from '../components/blog/blog_posts_section/blog_posts_section';

const Blog = ({
	pageContext: {
		featuredBlogPost,
		blogPosts,
		availableFilters,
		pageCount,
		currentPage
	}
}) => (
	<HelmetLayout>
		<MainLayout
			footerProps={{
				dismissedSections: ['create-demo-account', 'trusting-customers-list']
			}}
		>
			<BlogHeader {...{ featuredBlogPost }} />
			<BlogPostsSection
				{...{
					availableFilters,
					blogPosts,
					pageCount,
					currentPage
				}}
			/>
		</MainLayout>
	</HelmetLayout>
);

export default Blog;
