import React, { useRef } from 'react';
import { Link } from 'gatsby';

import moment from 'moment';
import { animated, config, useSpring } from 'react-spring';

import WithGradientLayout from '../../layouts/with_gradient_layout/with_gradient_layout';
import Card from '../../design_system/card/card';
import Tag from '../../design_system/tag/tag';
import Button from '../../design_system/button/button';

import classes from './blog_header.module.css';

const BlogHeader = ({ featuredBlogPost }) => (
	<WithGradientLayout className={classes.container}>
		<div className={classes.content}>
			<h1 className={classes.title}>
				{'Titre de l’article à la Une, en lieu et place sur deux lignes max, corps, typographie, etc…'}
			</h1>
			<FeaturedBlogPost
				{...featuredBlogPost}
			/>
		</div>
	</WithGradientLayout>
);

const FeaturedBlogPost = ({
	title,
	content,
	preview,
	publishedAt,
	id,
	tags
}) => {
	const momentDate = useRef(moment(publishedAt));
	const newTags = useRef([
		{ label: momentDate.current.format('MMMM'), color: '#8b6706' },
		{ label: momentDate.current.format('YYYY'), color: '#8cc047' },
		...tags
	]);
	const to = `/blog-post/${id}`;
	return (
		<div className={classes.featuredBlogPost}>
			<Card className={classes.previewContainer}>
				<Link {...{ to }}>
					<img
						className={classes.previewPicture}
						src={preview}
						alt={title}
					/>
				</Link>

			</Card>
			<div className={classes.featuredBlogPostTexts}>
				<div className={classes.tagsLineContainer}>
					<div className={classes.tagsContainer}>
						{newTags.current.map(tag => (
							<Tag
								className={classes.tag}
								key={`tag_${tag.label}`}
								{...tag}
							/>
						))}
					</div>
					<AnimatedLine />
				</div>
				<Link {...{ to }}>
					<div className={classes.featuredBlogPostContent}>
						{content}
					</div>
				</Link>
				<div className={classes.actionsContainer}>
					<Link {...{ to }}>
						<Button
							className={classes.button}
							color="primary"
							variant="underlined"
							bounce={false}
						>
							{'Lire la suite >'}
						</Button>
					</Link>
				</div>
			</div>
		</div>
	);
};

const AnimatedLine = () => {
	const { translation, ...otherSpringProps } = useSpring({
		from: {
			translation: 100,
			opacity: 0
		},
		to: {
			translation: 0,
			opacity: 1
		},
		config: {
			...config.molasses,
			friction: 100
		}
	});
	return (
		<animated.div
			className={classes.animatedLine}
			style={{
				transform: translation.interpolate(value => `translate3d(${value}%, 0, 0)`),
				...otherSpringProps
			}}
		/>
	);
}

export default BlogHeader;
