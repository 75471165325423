import React, { useCallback, useState } from 'react';

import Section from '../../design_system/section/section';
import SectionTitle from '../../design_system/section/section_title/section_title';
import TextField from '../../design_system/text_field/text_field';
import Pagination from '../../design_system/pagination/pagination';
import RotatedArrow from '../../small_views/rotated_arrow/rotated_arrow';

import BlogSmallPost from './blog_small_post/blog_small_post';
import CategoriesDropdown from './categories_dropdown/categories_dropdown';
import DateDropdown from './date_dropdown/date_dropdown';

import classes from './blog_post_section.module.css';

const BlogPostsSection = ({
	availableFilters, pageCount, currentPage, blogPosts
}) => {
	return (
		<Section
			className={classes.container}
			setVisibleOnScroll
		>
			{({ showSection }) => (
				<div className={classes.content}>
					<div className={classes.topBar}>
						<SectionTitle
							containerClassName={classes.sectionTitleContainer}
							className={classes.sectionTitle}
							dismissUnderline={showSection}
							underlineClassName={classes.sectionTitleUnderline}
						>
							{'Tous les articles'}
						</SectionTitle>
						<div
							id="blog-filters"
							className={classes.filters}
						>
							<Categories
								filtersToDisplay={availableFilters && availableFilters.categories}
							/>
							<Date />
							<TextField
								className={classes.searchField}
								inputClassName={classes.searchFieldInput}
								color="emerald"
								placeholder="Rechercher"
							/>
						</div>
					</div>
					<div
						id="blog-posts"
						className={classes.postsContainer}
					>
						{Object.entries(blogPosts).map(([id, blogPost]) => (
							<BlogSmallPost
								{...{
									id,
									blogPost
								}}
							/>
						))}
					</div>
					<Pagination
						className={classes.pagination}
						useLink
						total={pageCount}
						current={currentPage}
						linkPathBuild={index => `/blog${index > 0 ? `/${index + 1}` : ''}#blog-filters`}
					/>
				</div>
			)}
		</Section>
	)
};

const Categories = ({ filtersToDisplay }) => {
	const [open, setOpen] = useState(false);
	const [anchorElement, setAnchorElement] = useState(null);
	const handleMouseEnter = useCallback((event) => {
		setAnchorElement(event.currentTarget);
		setOpen(true);
	});
	const handleMouseLeave = useCallback(() => {
		setOpen(false);
	});
	return (
		<button
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onFocus={handleMouseEnter}
			onBlur={handleMouseLeave}
			type="button"
		>
			<CategoriesDropdown
				popperProps={{
					placement: 'bottom',
					modifiers: {
						flip: {
							enabled: false
						},
						preventOverflow: {
							enabled: true,
							boundariesElement: 'scrollParent'
						}
					}
				}}
				{...{
					anchorElement,
					open,
					filtersToDisplay
				}}
			/>
			<div className={classes.withDropdownText}>
				{'Catégories'}
				<RotatedArrow rotationValue={open ? 90 : 0} className={classes.arrow} />
			</div>
		</button>
	);
}

const Date = () => {
	const [open, setOpen] = useState(false);
	const [anchorElement, setAnchorElement] = useState(null);
	const handleMouseEnter = useCallback((event) => {
		setAnchorElement(event.currentTarget);
		setOpen(true);
	});
	const handleMouseLeave = useCallback(() => {
		setOpen(false);
	});
	return (
		<button
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onFocus={handleMouseEnter}
			onBlur={handleMouseLeave}
			type="button"
		>
			<DateDropdown
				popperProps={{
					placement: 'bottom',
					modifiers: {
						flip: {
							enabled: false
						},
						preventOverflow: {
							enabled: true,
							boundariesElement: 'scrollParent'
						}
					}
				}}
				{...{
					anchorElement,
					open
				}}
			/>
			<div className={classes.withDropdownText}>
				{'Date'}
				<RotatedArrow rotationValue={open ? 90 : 0} className={classes.arrow} />
			</div>
		</button>
	);
}


export default BlogPostsSection;
