import React, { useCallback, useState } from 'react';

import moment from 'moment';

import PopperCard from '../../../design_system/popper_card/popper_card';
import List from '../../../design_system/list/list';
import ListItem from '../../../design_system/list_item/list_item';
import Checkbox from '../../../design_system/checkbox/checkbox';

import classes from './date_dropdown.module.css';

const filtersToDisplay = [...new Array(12).fill('')].map((empty, index) => {
	return ({
		label: moment({ months: index }).format('MMMM')
	})
});

const DateDropdown = ({ ...other }) => {
	const [activeFilters, setActiveFilters] = useState([]);
	const [, setUpdateState] = useState();
	const handleSelection = useCallback((label) => {
		const newFilters = activeFilters;
		if (activeFilters.includes(label)) {
			newFilters.splice(activeFilters.indexOf(label), 1);
		} else {
			newFilters.push(label);
		}
		setActiveFilters(newFilters);
		setUpdateState({});
	}, [activeFilters]);
	return (
		<PopperCard
			className={classes.container}
			{...other}
		>
			<List>
				{filtersToDisplay.map(({ label }) => {
					const isActive = activeFilters && activeFilters.includes(label);
					return (
						<ListItem
							className={classes.listItem}
							button
							onClick={() => handleSelection(label)}
						>
							<Checkbox
								className={classes.checkbox}
								readOnly
								checked={isActive}
								variant="outlined"
								color="secondary"
							/>
							<span>
								{label}
							</span>
						</ListItem>
					);
				})}
			</List>
		</PopperCard>
	);
}

export default DateDropdown;
