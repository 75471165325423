import React, { useCallback, useRef, useState } from 'react';
import { Link } from 'gatsby';

import moment from 'moment';

import Tag from '../../../design_system/tag/tag';
import Button from '../../../design_system/button/button';

import classes from './blog_small_post.module.css';

const SmallPost = ({ id, blogPost }) => {
	if (!id || !blogPost) {
		return null;
	}
	const {
		preview, title, content, tags, publishedAt
	} = blogPost;
	const momentDate = useRef(moment(publishedAt));
	const newTags = useRef([
		{ label: momentDate.current.format('MMMM'), color: '#8b6706' },
		{ label: momentDate.current.format('YYYY'), color: '#8cc047' },
		...tags
	]);
	return (
		<div className={classes.container}>
			<div className={classes.previewContainer}>
				<img
					className={classes.previewPicture}
					src={preview}
					alt={title}
				/>
			</div>
			<div className={classes.texts}>
				<div className={classes.tagsContainer}>
					{newTags.current.map(tag => (
						<Tag
							className={classes.tag}
							key={`tag_${tag.label}`}
							{...tag}
						/>
					))}
				</div>
				<div className={classes.title}>
					{title}
				</div>
				<div className={classes.content}>
					{content}
				</div>
				<div className={classes.actionsContainer}>
					<Link to={`blog-post/${id}`}>
						<DiscoverButton />
					</Link>
				</div>
			</div>
		</div>
	);
}

const DiscoverButton = () => {
	const [color, setColor] = useState('emerald');
	const setColorPrimary = useCallback(() => setColor('primary'));
	const setColorEmerald = useCallback(() => setColor('emerald'));
	return (
		<Button
			className={classes.button}
			variant="underlined"
			bounce={false}
			onMouseEnter={setColorPrimary}
			onMouseLeave={setColorEmerald}
			onFocus={setColorPrimary}
			onBlur={setColorEmerald}
			{...{ color }}
		>
			{'En savoir plus >'}
		</Button>
	);
}

export default SmallPost;
